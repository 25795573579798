import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const Dleader = ({monitor}) => {
    const [list, setList] = useState([])

    const dumpingLeaders = useSelector((state) => state.monitorData[`dumpingLeaders`])

    useEffect(() => {
        if (dumpingLeaders) {
            let tmp = [...dumpingLeaders.data]
            let setTmp = []

            tmp.sort((a, b) => b.index - a.index)

            tmp.forEach((e, i) => {
                if (i > 4) {
                    return
                } else {
                    setTmp.push({
                        index: e.ratio,
                        name: e.seller
                    })
                }
            });

            setList(setTmp)
        }
    }, [dumpingLeaders])

    if (list.length === 0) return null

    return (
        <div className="compare-monitor">
            <h3>Лидеры демпинга</h3>
                <div>
                    {list.map((item, i) => 
                    <div key={i} className="d-leader">
                    <div style={{display: "flex"}}>
                        <span style={{display: 'inline-block', marginRight: '10px'}}>{i + 1}.</span>
                        <span style={{whiteSpace: "nowrap", overflow: "hidden", width: 150}}>{item.name}</span>
                    </div>
                    <div className="d-leader-index">
                        <span style={{display: 'inline-block', marginRight: '6px'}}>{item.index}</span>
                        <span>

                        </span>
                    </div>
                </div>
                )}
            </div>
        </div>
    )
}

export default Dleader
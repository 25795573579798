import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDumpingCalenderDay, setDumpingCalenderDayFilter, updateDumpingCalenderDayItem } from "../../../../store/AppStore/dumpingData"
import Switcher from "../../../../components/Switcher"
import ModalFS from "../../../../components/Modal/fullScreen"
import { gatewayURL } from "../../../../store/api"

const CalenderModal = ({data}) => {

    const dispatch = useDispatch()

    const calenderDayFilter = useSelector((state) => state.dumpingData.calenderDayFilter)

    useEffect(() => {
        dispatch(getDumpingCalenderDay(data.date))
    }, [dispatch, data, calenderDayFilter])

    return (
        <div>
            <div>
                <h2>{data.day.d} {data.day.m}</h2>
            </div>
            <div className="flex" style={{alignItems: "flex-start"}}>
                <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginRight: 20, border: "1px solid #ccc"}}><Filter /></div>
                <div style={{flexGrow: 1}}><List date={data.date} /></div>
            </div>
        </div>
    )
}

export default CalenderModal

const Filter = () => {
    const calenderDayFilter = useSelector((state) => state.dumpingData.calenderDayFilter)

    const dispatch = useDispatch()

    const handler = (e) => {
        switch (e.target.id) {
            case "viewed":
                dispatch(setDumpingCalenderDayFilter({nv: calenderDayFilter.nv, v: e.target.checked, np: false}))
                break
            case "noviewed":
                dispatch(setDumpingCalenderDayFilter({nv: e.target.checked, v: calenderDayFilter.v, np: false}))
                break
            case "noproduct":
                if (e.target.checked) {
                    dispatch(setDumpingCalenderDayFilter({nv: false, v: false, np: true}))
                } else {
                    dispatch(setDumpingCalenderDayFilter({nv: true, v: false, np: false}))
                }
                break
            default:
                dispatch(setDumpingCalenderDayFilter({nv: true, v: false, np: false}))
                break
        }
    }

    return (
        <>
            <div>
                <div style={{marginBottom: 12}}>
                    <Switcher label="Необработанные" active={calenderDayFilter.nv} id="noviewed" handler={handler} />
                </div>
                <div style={{marginBottom: 12}}>
                    <Switcher label="Обработанные" active={calenderDayFilter.v} id="viewed" handler={handler} />
                </div>
            </div>
            <div style={{borderTop: "1px solid #ccc", paddingTop: 12}}>
                <Switcher label="Ненайденные товары" active={calenderDayFilter.np} id="noproduct" handler={handler} />
            </div>
        </>
    )
}

const List = ({date}) => {

    const calenderDay = useSelector((state) => state.dumpingData.calenderDay)

    const [o, setO] = useState(null)
    
    const handlerClose = () => {
        setO(null)
    }

    if (!calenderDay) {
        return null
    }

    return (
        <>
            <table className="table mb-24">
                <thead>
                    <tr>
                        <th></th>
                        <th>Продавец</th>
                        <th>Продукт</th>
                        <th>РРЦ</th>
                        <th>Цена</th>
                        <th>Маркетплейс</th>
                        <th>Прайсер</th>
                    </tr>
                </thead>
                <tbody>
                    {calenderDay.map(e => <Row key={e.ID} data={e} handler={setO} />)}
                </tbody>
            </table>

            {o ? <ModalFS close={handlerClose} body={<CalenderModalDayRow data={o} date={date} />} /> : null}
        </>
    )
}

const Row = ({data, handler}) => {
    return (
        <tr onClick={() => handler(data)}>
            <td><div className={data.view ? "dot final" : "dot red"}></div></td>
            <td>{data.b_seller.name}</td>
            <td>{data.b_product.title}</td>
            <td>{data.productPrice}</td>
            <td>{data.parsePrice}</td>
            <td>{data.shopCode}</td>
            <td><div className={data.dumpingType === "pricer" ? "dot final" : "dot red"}></div></td>
        </tr>
    )
}

const CalenderModalDayRow = ({data, date}) => {
    const dispatch = useDispatch()

    const [match, setMatch] = useState(false)

    useEffect(() => {
        if (data.checkedMatch) {
            setMatch(true)
        }
    }, [data])
    
    const handler = () => {
        let update = {
            dumpingType: data.dumpingType,
            ID: data.ID,
            view: !data.view,
            checkedMatch: match
        }
        dispatch(updateDumpingCalenderDayItem(update, date))
    }

    return (
        <div style={{padding: 20}}>
            <table className="table-tab mb-24">
                <tbody>
                    <tr>
                        <td>Товар</td>
                        <td>{data.b_product.title}</td>
                    </tr>
                    <tr>
                        <td>Продавец</td>
                        <td>{data.b_seller.name}</td>
                    </tr>
                    <tr>
                        <td>Ссылка</td>
                        <td><a href={data.url} target="_blank" rel="noreferrer">{data.url}</a></td>
                    </tr>
                    <tr>
                        <td>Маркетплейс</td>
                        <td>{data.shopCode}</td>
                    </tr>
                    <tr>
                        <td>РРЦ</td>
                        <td>{data.productPrice}</td>
                    </tr>
                    <tr>
                        <td>Цена в магазине</td>
                        <td><span className={data.parsePrice < data.productPrice ? "tdspan alert" : "tdspan"}>{data.parsePrice}</span></td>
                    </tr>
                    {data.screenShot ?
                    <tr>
                        <td>Скриншот</td>
                        <td> <img src={`${gatewayURL}/api/v1/b/images/shot/${data.ID}/${data.screenShot}`} alt={"screenshot"} /> </td>
                    </tr> : null }
                    {data.targetMin && data.targetMax ? 
                    <tr>
                        <td>Диапазон в прайсере</td>
                        <td><span className={data.targetMin < data.productPrice ? "tdspan alert" : "tdspan"}>{data.targetMin}</span> - <span className={data.targetMax < data.productPrice ? "tdspan alert" : "tdspan"}>{data.targetMax}</span></td>
                    </tr> : null }
                </tbody>
            </table>
            <div className="mb-12">
                <label htmlFor={"active"}>
                        <input 
                            id={"active"} 
                            checked={match}
                            onChange={e => setMatch(e.target.checked)} 
                            type="checkbox"/>
                        Не совпадает на разных ПК
                    </label>
            </div>
            <div>
                <button onClick={handler} className="button">Отметить {data.view ? "непросмотренным" : "просмотренным"}</button>
            </div>
        </div>
    )
}
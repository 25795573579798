import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const dumpingData = createSlice({
    name: 'dumpingData',
    initialState: {
        graphPeriod : "week",
        graphData : null,
        calenderPeriod : "week",
        calenderData : null,
        calenderDay: null,
        calenderDayFilter: {nv: true, v: false, np: false},
        raiting: null
    },
    reducers: {
        setDumpingGraphPeriod: (state, action) => {
            state.graphPeriod = action.payload
        },
        setDumpingGraphData: (state, action) => {
            state.graphData = action.payload
        },
        setDumpingCalenderPeriod: (state, action) => {
            state.calenderPeriod = action.payload
        },
        setDumpingCalenderData: (state, action) => {
            state.calenderData = action.payload
        },
        setDumpingCalenderDay: (state, action) => {
            state.calenderDay = action.payload
        },
        setDumpingCalenderDayFilter: (state, action) => {
            state.calenderDayFilter = action.payload
        },
        setDumpingRaiting: (state, action) => {
            state.raiting = action.payload
        }
    }
})

export const { 
    setDumpingGraphPeriod,
    setDumpingGraphData,
    setDumpingCalenderPeriod,
    setDumpingCalenderData,
    setDumpingCalenderDay,
    setDumpingCalenderDayFilter,
    setDumpingRaiting
} = dumpingData.actions

export const getDumpingGraphData = (period) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/dumping/graph`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            "period" : period
         }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setDumpingGraphData(response.data.result))
            } 
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingGraphData(null))
        });
}

export const getDumpingCalenderData = (f, t) => (dispatch, getState) => {
    let token = getState().auth.token
    let calenderPeriod = getState().dumpingData.calenderPeriod
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/dumping/calender`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            period: calenderPeriod,
            "from" : f,
            "to" : t
         }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                dispatch(setDumpingCalenderData(response.data.result.data))
            } 
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingCalenderData(null))
        });
}

export const getDumpingCalenderDay = (date) => (dispatch, getState) => {
    let token = getState().auth.token
    let calenderDayFilter = getState().dumpingData.calenderDayFilter
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/b/dumping/calender/day`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            date: date,
            viewed: calenderDayFilter.v ?? false,
            noviewed: calenderDayFilter.nv ?? false,
            noproduct: calenderDayFilter.np ?? false
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.data) {
                dispatch(setDumpingCalenderDay(response.data.result.data))
            } else {
                dispatch(setDumpingCalenderDay([]))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingCalenderDay(null))
        });
}

export const updateDumpingCalenderDayItem = (data, date) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/b/dumping/calender/day`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            dispatch(getDumpingCalenderDay(date))
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingCalenderDay(null))
        });
}

export const getDumpingRaiting = (date) => (dispatch, getState) => {
    let token = getState().auth.token
    let calenderDayFilter = getState().dumpingData.calenderDayFilter
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/dumping/raiting`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: {
            date: date,
            viewed: calenderDayFilter.v ?? false,
            noviewed: calenderDayFilter.nv ?? false,
            noproduct: calenderDayFilter.np ?? false
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result.data) {
                dispatch(setDumpingRaiting(response.data.result.data))
            } else {
                dispatch(setDumpingRaiting([]))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingCalenderDay(null))
        });
}

export default dumpingData.reducer
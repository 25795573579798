import { useDispatch, useSelector } from "react-redux"
import TopBlock from "../../../../components/TopBlock"
import { useEffect, useState } from "react"
import { getAnalyseRoundBySellers, getAnalysetableBySellers, getPAnalyseRoundByProducts, getPAnalysetableByProducts, getProdDataList, setProdDataSelected } from "../../../../store/AppStore/productsData"
import { gatewayURL } from "../../../../store/api"
import ModalFS from "../../../../components/Modal/fullScreen"
import ProductAppPage from "./product"
import SellerMainGraphic from "./SellerMainGraphic"
import FromToSelector from "../../../../components/FromToSelector"
import SellerRoundGraphic from "./SellerRoundGraphic"
import SellerRoundGraphicSellers from "./SellerRoundGraphicSellers"

const ProductsAppPage = () => {
    return (
        <>
            <TopBlock title="Продукция" back="Главная" backLink={"/app"} />
            <div className="flex" style={{alignItems: "flex-start"}}>
                <ProductsList />
                <ProductsAnalyse />

            </div>
        </>
    )
}

export default ProductsAppPage

const ProductsList = () => {

    const dispatch = useDispatch()
    
    const list = useSelector((state) => state.productsData.list)
    const selected = useSelector((state) => state.productsData.selected)

    const [sl, setSL] = useState([])
    const [sa, setSA] = useState(false)
    const [os, setOS] = useState(null)

    useEffect(() => {
        if (!list) {
            dispatch(getProdDataList())
        } else {
            if (list.length > 0) {
                if (selected.length === 0) {
                    let tmp = []
    
                    list.forEach((element, i) => {
                        if (i < 3) {
                            tmp.push(element.b_product.ID)
                        }
                    });
    
                    dispatch(setProdDataSelected(tmp))
                } else {
                    let tmp = []
    
                    list.forEach(element => {
                        let e = Object.assign({}, element)
                        if (selected.includes(element.b_product.ID)) {
                            e.selected = true
                        }else {
                            e.selected = false
                        }
                        tmp.push(e)
                    });
        
                    setSL(tmp)
                }
            }
        }
    }, [dispatch, list, selected])

    const handlerCheck = (check, id) => {
        let tmp = [...selected]
        if (check) {
            tmp.push(id)
        } else {
            tmp = tmp.filter(el => el !== id)

            dispatch(setProdDataSelected([]))
            setSA(false)

        }
        dispatch(setProdDataSelected(tmp))
    }

    const handlerCheckAll = (check) => {
        
        let tmp = []

        if (check) {
            list.forEach(element => {
                tmp.push(element.b_product.ID)
            });
        }

        dispatch(setProdDataSelected(tmp))
        setSA(check)
    }

    const handlerOpenSeller = (id) => {
        setOS(id)
    }

    const handlerCloseSeller = () => {
        setOS(null)
    }

    return (
        <>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, height: "70vh"}}>
                <div style={{height: "100%", overflowY: "auto"}}>
                    <table className="table-tab">
                        <thead>
                            <tr>
                                <th style={{width: 35}}>
                                    <input type="checkbox" checked={sa} onChange={el => handlerCheckAll(el.target.checked)} />
                                </th>
                                <th style={{width: 120}}>Выбрать все</th>
                                <th style={{width: 120}}>Название</th>
                                <th style={{textAlign: "right", width: 120}} width={120} align="right">Объем заказов, шт.</th>
                                <th style={{textAlign: "right", width: 120}} width={120} align="right">% от объема заказов</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sl.map((e, i) => 
                            <tr key={i}>
                                <td><input type="checkbox" checked={e.selected} onChange={el => handlerCheck(el.target.checked, e.b_product.ID)} /></td>
                                <td><img style={{maxWidth: 50, maxHeight: 50}} src={`${gatewayURL}/api/v1/b/images/${e.b_product.ID}/${e.b_product.preview}`} alt={e.b_product.title} /></td>
                                <td><span className="link" onClick={() => handlerOpenSeller(e.b_product)}>{e.b_product.title}</span></td>
                                <td style={{textAlign: "right"}} align="right">{e.salesVolume.value ? e.salesVolume.value.toLocaleString("ru-RU") : 0}</td>
                                <td style={{textAlign: "right"}} align="right">{e.salesVolume.percent ? e.salesVolume.percent : 0} %</td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {os ? <ModalFS close={handlerCloseSeller} body={<ProductAppPage product={os} />} /> : null}
        </>
    )
}

const ProductsAnalyse = () => {

    const selected = useSelector((state) => state.productsData.selected)
    
    const dispatch = useDispatch()
    const [f, setF] = useState("")
    const [t, setT] = useState("")
    
    const handlerUpdate = (f, t) => {
        setF(f)
        setT(t)
    }

    const converter = (num) => {
        if (num < 10) {
            return '0' + num
        } else {
            return num
        }
    }

    useEffect(() => {
        if (t === "") {
            let d = new Date()
            let fd = new Date()
            fd.setDate(d.getDate() - 15)
            setT(`${d.getFullYear()}-${converter(d.getMonth() + 1)}-${converter(d.getDate())}`)
            setF(`${fd.getFullYear()}-${converter(fd.getMonth() + 1)}-${converter(fd.getDate())}`)
        }
    }, [t])

    useEffect(() => {
        if (f !== "" && t !== "" && selected.length > 0) {
            dispatch(getPAnalyseRoundByProducts(f, t, selected))
            dispatch(getPAnalysetableByProducts(f, t, selected))
            dispatch(getAnalyseRoundBySellers(f, t, selected))
            dispatch(getAnalysetableBySellers(f, t, selected))
        }
    }, [dispatch, f, t, selected])

    return (
        <>
            <div style={{marginLeft: 20, flexGrow: 1}}>
                <h2 style={{marginTop: 0}}>Анализ выбранной продукции</h2>
                
                <SellerMainGraphic />

                <div>
                    <FromToSelector from={f} to={t} handler={handlerUpdate} />
                </div>

                <div>
                    <SellerStatByProduct />
                    <SellerStatBySellers />
                </div>
            </div>
        </>
    )
}


const SellerStatByProduct = () => {
    const analyseTableByProducts = useSelector((state) => state.productsData.analyseTableByProducts)

    if (!analyseTableByProducts || !analyseTableByProducts.rows) {
        return null
    }

    return (
        <div className="flex" style={{marginBottom: 24}}>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, flexGrow: 1, height: 380, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div style={{overflowY: "scroll", height: 340, position: 'relative'}}>
                    <table className="table-tab">
                        <thead>
                            <tr style={{position: "sticky", top: 0}}>
                                <th></th>
                                <th>Товар</th>
                                <th>Количество, шт.</th>
                                <th>Доля от заказов, %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyseTableByProducts.rows.map((e, i) => 
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td>{e.title}</td>
                                    <td>{e.count}</td>
                                    <td>{e.percent} %</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr style={{position: "sticky", bottom: 0, background: "#fff", fontWeight: 600}}>
                                <td style={{paddingTop: 16}}></td>
                                <td style={{paddingTop: 16}}>ИТОГО</td>
                                <td style={{paddingTop: 16}}>{analyseTableByProducts.result.count}</td>
                                <td style={{paddingTop: 16}}>{analyseTableByProducts.result.percent} %</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginLeft: 20, flex: "0 0 500px", height: 380}}>
                    <h3 style={{marginTop: 0}}>Заказы</h3>
                    <div style={{height: 300}}>
                        <SellerRoundGraphic />
                    </div>
            </div>
        </div>
    )
}

const SellerStatBySellers = () => {
    const analyseTableBySales = useSelector((state) => state.productsData.analyseTableBySellers)

    if (!analyseTableBySales || !analyseTableBySales.rows) {
        return null
    }

    return (
        <div className="flex" style={{marginBottom: 24}}>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, flexGrow: 1, height: 380, display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
                <div style={{overflowY: "scroll", height: 340, position: 'relative'}}>
                    <table className="table-tab">
                        <thead>
                            <tr style={{position: "sticky", top: 0}}>
                                <th></th>
                                <th>Товар</th>
                                <th>Количество, шт.</th>
                                <th>Доля от заказов, %</th>
                                <th>Объем заказов, руб.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analyseTableBySales.rows.map((e, i) => 
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td>{e.title}</td>
                                    <td>{e.count}</td>
                                    <td>{e.percent} %</td>
                                    <td>{e.sum.toLocaleString("ru-RU")}</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr style={{position: "sticky", bottom: 0, background: "#fff", fontWeight: 600}}>
                                <td style={{paddingTop: 16}}></td>
                                <td style={{paddingTop: 16}}>ИТОГО</td>
                                <td style={{paddingTop: 16}}>{analyseTableBySales.result.count}</td>
                                <td style={{paddingTop: 16}}>{analyseTableBySales.result.percent} %</td>
                                <td style={{paddingTop: 16}}>{analyseTableBySales.result.sum.toLocaleString("ru-RU")}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginLeft: 20, flex: "0 0 500px", height: 380}}>
                    <h3 style={{marginTop: 0}}>Заказы</h3>
                    <div style={{height: 300}}>
                        <SellerRoundGraphicSellers />
                    </div>
            </div>
        </div>
    )
}
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDumpingCalenderData, setDumpingCalenderPeriod } from "../../../../store/AppStore/dumpingData"
import Preloader from "../../../../components/Preloader"
import ArrL from "../../../../assets/images/arr-l.svg"
import ArrR from "../../../../assets/images/arr-r.svg"
import "./style.css"
import ModalFS from "../../../../components/Modal/fullScreen"
import CalenderModal from "./calenderModal"

const DumpingCalender = () => {
    return (
        <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginBottom: 24}}>
            <SelectorStep />
            <Calender />
        </div>
    )
}

export default DumpingCalender

const SelectorStep = () => {

    const dispatch = useDispatch()

    const period = useSelector((state) => state.dumpingData.calenderPeriod)

    const [list, setList] = useState([])

    const handler = (period) => {
        dispatch(setDumpingCalenderPeriod(period))
    }

    useEffect(() => {
        const steps = [
            {
                name: 'week',
                label: "По неделям"
            },
            {
                name: 'month',
                label: "По месяцам"
            },
            {
                name: 'noview',
                label: "Непросмотренные"
            }
        ]

        let tmp = []

        steps.forEach((e, i) => {
            let data = {
                name: e.name,
                label: e.label,
                active: e.name === period
            }

            tmp.push(data)
        })

        setList(tmp)
    }, [period])

    return (
        <div className="button-group">
            {list.map((e, i) => <button className={`button-group-item ${e.active}`} key={i} onClick={() => handler(e.name)}>{e.label}</button>)}
        </div>
    )
}

const Calender = () => {
    const dispatch = useDispatch()

    const [ft, setFT] = useState(null)
    const [cp, setCP] = useState(null)
    const [o, setO] = useState(0)

    const calenderPeriod = useSelector((state) => state.dumpingData.calenderPeriod)
    
    const converter = (num) => {
        if (num < 10) {
            return '0' + num
        } else {
            return num
        }
    }

    useEffect(() => {
        if (cp !== calenderPeriod) {
            setO(0)
            setCP(calenderPeriod)
        } else {
            let td = new Date()
            let fd = new Date()
            let tmp = new Date()
            if (calenderPeriod === "week") {
                tmp.setDate(tmp.getDate() - 6 - o)
                fd = new Date(tmp)
                tmp.setDate(tmp.getDate() + 6)
                td = tmp
                setFT({f : `${fd.getFullYear()}-${converter(fd.getMonth() + 1)}-${converter(fd.getDate())}`, t: `${td.getFullYear()}-${converter(td.getMonth() + 1)}-${converter(td.getDate())}`})
            } else if (calenderPeriod === "month") {
                let m = td.getMonth() - o
                fd.setMonth(m)
                td.setMonth(m + 1)
                td.setDate(1)
                td.setDate(td.getDate() - 1)
                setFT({f: `${fd.getFullYear()}-${converter(fd.getMonth() + 1)}-01`, t: `${td.getFullYear()}-${converter(td.getMonth() + 1)}-${converter(td.getDate())}`})
            } else {
                setFT({})
            }
        }
    }, [calenderPeriod, o, cp])

    useEffect(() => {
        if (ft !== null) {
            dispatch(getDumpingCalenderData(ft.f, ft.t))
        }
    }, [ft, dispatch])

    return (
        <div className="flex" style={{alignItems: "center"}}>
            <div className="calender-arrow">
                <img style={{cursor: "pointer"}} onClick={() => setO(o + 1)} src={ArrL} alt="" />
            </div>
            <CalenderGrid />
            <div className="calender-arrow">
                {o > 0 ? <img style={{cursor: "pointer"}} onClick={() => setO(o - 1)} src={ArrR} alt="" /> : null}
            </div>
        </div>
    )
}

const CalenderGrid = () => {
    const calenderData = useSelector((state) => state.dumpingData.calenderData)
    
    const [d, setD] = useState(null)

    const handlerClose = () => {
        setD(null)
    }

    if (!calenderData || calenderData.length === 0) {
        return <Preloader />
    }

    return (
        <>
            <div className="calender-grid">
                {calenderData.map((e, i) => <CalenderItem key={i} data={e} handler={setD} />)}
            </div>
            {d ? <ModalFS close={handlerClose} body={<CalenderModal data={d} />} /> : null}
        </>
    )
}

var days = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];

const CalenderItem = ({data, handler}) => {
    const [d, setD] = useState(null)
    const [all, setAll] = useState(0)
    const [ok, setOk] = useState(0)
    const [demp, setDemp] = useState(0)
    const [nop, setNop] = useState(0)
    const [c, setC] = useState(true)

    useEffect(() => {
        let dt = new Date(data.date)
        setD(
            {
                m: dt.toLocaleString('default', { month: 'long' }),
                d: dt.getDate(),
                l: days[dt.getDay()]
            }
        )
    }, [data])

    useEffect(() => {
        let o = 0, dm = 0, v = 0, n = 0
        data.data.forEach(e => {
            let ee = e.value ?? 0
            if (e.label === "ok") {
                o = o + ee
            } else if (e.label === "dumping") {
                dm = dm + ee
            } else if (e.label === "null") {
                n = n + ee
            } else if (e.label === "viewed") {
                v = v + ee
            }
        })
        setOk(o)
        setDemp(dm)
        setNop(n)
        setAll(o + dm + n)

        if (dm > v) {
            setC(false)
        } else {
            setC(true)
        }
    }, [data])
    

    if (!d) return null

    return (
        <div onClick={() => handler({date: data.date, day: d})} className="calender-blockitem" style={{backgroundColor: c ? "#fff" : "#FFDCDC", borderColor: c ? "#ddd" : "#FFDCDC"}}>
            <div className="mb-12">
                <div className="calender-date">
                    <span>{d.d}</span> {d.m}
                </div>
                <div style={{fontSize: 14, color: "#777", marginTop: -5}}>{d.l}</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ddd", padding: "8px 0"}}>
                <div>Проверено</div>
                <div style={{fontWeight: 600}}>{all}</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ddd", padding: "8px 0"}}>
                <div>ОК</div>
                <div style={{fontWeight: 600}}>{ok}</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", borderBottom: "1px solid #ddd", padding: "8px 0"}}>
                <div>Демпинг</div>
                <div style={{fontWeight: 600}}>{demp}</div>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", padding: "8px 0"}}>
                <div>Нет товара</div>
                <div style={{fontWeight: 600}}>{nop}</div>
            </div>
        </div>
    )
}
import "./style.css"
import Logo from "../../../../assets/images/logo-full.svg"
import Stringer from "./m-string"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getBarDataMonitor, getDumpingLeaders, getLeaderDataMonitor, getMonthDataMonitor, getRadarDataMonitor, getStringDataMonitor, setMonitorTime } from "../../../../store/AppStore/monitorData"
import SalesBlock from "./m-sales"
import Monthes from "./m-month"
import { getCount } from "../../../../store/AppStore/appData"
import MLeader from "./m-leader"
import Dleader from "./d-leader"
import { useParams } from "react-router-dom"

const Monitor = () => {

    const {monitor} = useParams()

    const dispatch= useDispatch()

    const time = useSelector((state) => state.monitorData.monitorTime)
    
    useEffect(() => {
        dispatch(getBarDataMonitor(monitor))
        dispatch(getStringDataMonitor(monitor))
        dispatch(getMonthDataMonitor(monitor))
        dispatch(getCount("dumping"))
        dispatch(getLeaderDataMonitor(monitor))
        dispatch(getDumpingLeaders())
        dispatch(getRadarDataMonitor(monitor))
    }, [time, dispatch, monitor])

    useEffect(() => {
        const timeInterval = setInterval(() => {
            let nd = new Date()            

            if (nd.getSeconds() === 0) {
                dispatch(setMonitorTime(nd.getTime()))
            }
        }, 1000);
    
        return () => clearInterval(timeInterval);
    }, [dispatch]);

    return (
        <div className="monitor-main">
            <div className="monitor-header">
                <img src={Logo} className="monitor-logo" alt="Cerasus" />
                <div>— система онлайн-мониторинга маркетплейсов</div>
            </div>
            <div className="flex">
                <div className="monitor-left">
                    <SalesBlock type="count" monitor={monitor} />
                    <SalesBlock type="sum" monitor={monitor} />
                    <MLeader monitor={monitor} />
                </div>
                <div className="monitor-right">
                    <AttentionBlock />
                    <Monthes monitor={monitor} />
                    <Dleader monitor={monitor} />
                </div>
            </div>
            <Stringer monitor={monitor} />
        </div>
    )
}

export default Monitor

const AttentionBlock = () => {
    const dumpingCount = useSelector((state) => state.appData.dumpingCount)

    const [sum, setSum] = useState(0)

    useEffect(() => {
        let a, b
        a = dumpingCount.forCompanies?? 0
        b = dumpingCount.nullCompanies?? 0
        setSum(a + b)
    }, [dumpingCount])

    return (
        <div className="compare-monitor" style={{paddingTop: 16, paddingBottom: 16}}>
            <div style={{fontWeight: 600, display: "flex", alignItems: "center"}}>
                <div style={{fontSize: 70, marginRight: 20}}>{sum}</div>
                <div style={{fontSize: 20}}>Требуют внимания</div>
            </div>
        </div>
    )
}
import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
import { updateMessagesList } from '../System/messages';
import { setUser } from '../AuthStore/auth';
axios.defaults.withCredentials = true;

export const profile = createSlice({
    name: 'profile',
    initialState: {
        data: null,
        roles: [],
        users: [],
        cerasusBrand: null
    },
    reducers: {
        setProfileData: (state, action) => {
            state.data = action.payload
        },
        setProfileRoles: (state, action) => {
            state.roles = action.payload
        },
        setProfileUsers: (state, action) => {
            state.users = action.payload
        },
        setCerasusBrand: (state, action) => {
            state.cerasusBrand = action.payload
        },
    }
})

export const { setProfileData, setProfileRoles, setProfileUsers, setCerasusBrand } = profile.actions

export const getProfileRoles = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/roles`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(setProfileRoles(response.data.result.roles))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getBrand = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/company/brand`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(setCerasusBrand(response.data.result))
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getCompanyUsers = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/company/users`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(setProfileUsers(response.data.result.users))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const createUserProfile = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'post',
        url: `${gatewayURL}/api/v1/users`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(data)
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getCompanyUsers())
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const updateUserProfile = (id, data, self) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/users/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(data)
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            if (self) {
                dispatch(getUserData(id))
            }

            dispatch(getCompanyUsers())
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            let msg = error.response.data.result ?? "Error"
            dispatch(updateMessagesList(
                {
                    message: msg,
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const updateCompanyProfile = (data) => (dispatch, getState) => {
    let token = getState().auth.token
    let user = getState().auth.user
    
    let config = {
        method: 'put',
        url: `${gatewayURL}/api/v1/company`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        data: JSON.stringify(data)
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            dispatch(getUserData(user.ID))
            dispatch(updateMessagesList(
                {
                    message: "OK",
                    type: "ok",
                    time: t.getTime()
                }
            ))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export const getUserData = (id) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/users/${id}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    let t = new Date()

    axios.request(config)
        .then((response) => {
            localStorage.setItem('user', JSON.stringify(response.data.result))
            dispatch(setUser(response.data.result))
            //console.log(response.data.result)
            //dispatch(setProfileData(response.data.result))
        })
        .catch((error) => {
            console.log(error);
            dispatch(updateMessagesList(
                {
                    message: "msg",
                    type: "error",
                    time: t.getTime()
                }
            ))
        });
}

export default profile.reducer
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getDumpingRaiting } from "../../../../store/AppStore/dumpingData"

const DumpingRaiting = () => {

    const dispatch = useDispatch()

    const raiting = useSelector((state) => state.dumpingData.raiting)

    const [max, setMax] = useState(0.001)

    useEffect(() => {
        dispatch(getDumpingRaiting())
    }, [dispatch])

    useEffect(() => {
        if (raiting && raiting.length > 0) {
           setMax(raiting[0].ratio)
        }
    }, [raiting])

    if (!raiting) return null

    return (
        <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginBottom: 24}}>
            <table className="table-tab">
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Продавец</th>
                        <th>Коэффициент демпинга</th>
                        <th>Количество товаров</th>
                        <th>Количество заказов <div style={{fontSize: 14, fontWeight: 100, color: "#555"}}>За последние 30 дней</div></th>
                        <th>Объем заказов, руб. <div style={{fontSize: 14, fontWeight: 100, color: "#555"}}>За последние 30 дней</div></th>
                        <th>Прайсер</th>
                    </tr>
                </thead>
                <tbody>
                    {raiting.map((e, i) => <Row key={i} data={e} index={i + 1} max={max} />)}
                </tbody>
            </table>
        </div>
    )
}

export default DumpingRaiting

const Row = ({data, index, max}) => {

    const w = (100/max)*data.ratio

    return (
        <tr>
            <td>{index}</td>
            <td>{data.seller}</td>
            <td>
                <div className="flex">
                    <div style={{flex: "0 0 70px", fontWeight: 600}}>{data.ratio}</div>
                    <div style={{flexGrow: 1}}>
                        <div style={{backgroundColor: "red", height: 20, width: `${w}%`}}></div>
                    </div>
                </div>
            </td>
            <td>{data.products}</td>
            <td>{data.orders ?? "-"}</td>
            <td>{data.sum ? data.sum.toLocaleString("ru-RU") : "-"}</td>
            <td><div className={data.pricer === 1 ? "dot final" : data.pricer === 2 ?"dot yellow" : "dot red"}></div></td>
        </tr>
    )
}
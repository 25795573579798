import TopBlock from "../../../../components/TopBlock"
import DumpingCalender from "./dumpingCalender"
import DumpingMainGraph from "./DumpingMainGraphic"
import DumpingRaiting from "./DumpingRaiting"

const DumpingsAppPage = () => {
    return (
        <>
            <TopBlock title="Демпинг" back="Главная" backLink={"/app"} />
            <DumpingMainGraph />
            <DumpingCalender />
            <DumpingRaiting />
        </>
    )
}

export default DumpingsAppPage
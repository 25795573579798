import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { getAnalyseRoundBySellersOne, getAnalysetableBySellerOnes, getProductWidget, setAnalyseRoundBySellersOne, setAnalysetableBySellerOnes, setProductWidget } from "../../../../store/AppStore/productData"
import FromToSelector from "../../../../components/FromToSelector"
import CounterWidget from "../../../../components/Counter"
import Seller from "../../../../assets/images/seller.svg"
import OneSellerMainGraphic from "./OneSellerMainGraphic"
import OneSellerRoundGraphic from "./OneSellerRoundGraphic"

const ProductAppPage = ({product}) => {
    const dispatch = useDispatch()

    const productsWidget = useSelector((state) => state.productData.productsWidget)

    const [f, setF] = useState("")
    const [t, setT] = useState("")
    
    const handlerUpdate = (f, t) => {
        setF(f)
        setT(t)
    }

    const converter = (num) => {
        if (num < 10) {
            return '0' + num
        } else {
            return num
        }
    }

    useEffect(() => {
        if (t === "") {
            let d = new Date()
            let fd = new Date()
            fd.setDate(d.getDate() - 15)
            setT(`${d.getFullYear()}-${converter(d.getMonth() + 1)}-${converter(d.getDate())}`)
            setF(`${fd.getFullYear()}-${converter(fd.getMonth() + 1)}-${converter(fd.getDate())}`)
        }
    }, [t])

    useEffect(() => {
        if (f !== "" && t !== "") {
            dispatch(getAnalyseRoundBySellersOne(f, t, product.ID))
            dispatch(getAnalysetableBySellerOnes(f, t, product.ID))
        }
    }, [dispatch, f, t, product])

    useEffect(() => {
        dispatch(getProductWidget(product.ID))
    }, [product.ID, dispatch])

    useEffect(() => {
        return () => {
            dispatch(setAnalyseRoundBySellersOne(null))
            dispatch(setAnalysetableBySellerOnes(null))
            dispatch(setProductWidget(null))
        }
    }, [dispatch])

    return (
        <div>
            <h1>{product.title}</h1>
            <div className="flex">
                <div style={{flexGrow: 1}}>
                    <OneSellerMainGraphic sellerID={product.ID} />
                    <div>
                        <FromToSelector from={f} to={t} handler={handlerUpdate} />
                    </div>
                    <div>
                        <SellerStatByProduct />
                    </div>
                </div>
                {productsWidget ? 
                    <div style={{flex: "0 0 350px", marginLeft: 20}}>
                        <CounterWidget
                                title={"Продают"}
                                color={"#DC2058"}
                                number={productsWidget.value ?? 0}
                                numberTitle="продавцов"
                                percent={`${productsWidget.percent ?? 0} %`}
                                icon={Seller} />
                    </div> : null }
            </div>
        </div>
    )
}

export default ProductAppPage


const SellerStatByProduct = () => {
    const sellerATableByProducts = useSelector((state) => state.productData.analyseTableBySellers)

    if (!sellerATableByProducts || !sellerATableByProducts.rows) {
        return null
    }

    return (
        <div className="flex" style={{marginBottom: 24}}>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, flexGrow: 1, height: 380, display: "flex", flexDirection: "column", justifyContent: "space-between", border: "1px solid #ccc"}}>
                <div style={{overflowY: "scroll", height: 340, position: 'relative'}}>
                    <table className="table-tab">
                        <thead>
                            <tr style={{position: "sticky", top: 0}}>
                                <th></th>
                                <th>Товар</th>
                                <th>Количество, шт.</th>
                                <th>Доля от заказов, %</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sellerATableByProducts.rows.map((e, i) => 
                                <tr key={i}>
                                    <td>{i+1}.</td>
                                    <td>{e.title}</td>
                                    <td>{e.count}</td>
                                    <td>{e.percent} %</td>
                                </tr>)}
                        </tbody>
                        <tfoot>
                            <tr style={{position: "sticky", bottom: 0, background: "#fff", fontWeight: 600}}>
                                <td style={{paddingTop: 16}}></td>
                                <td style={{paddingTop: 16}}>ИТОГО</td>
                                <td style={{paddingTop: 16}}>{sellerATableByProducts.result.count}</td>
                                <td style={{paddingTop: 16}}>{sellerATableByProducts.result.percent} %</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 12, marginLeft: 20, flex: "0 0 500px", height: 380, border: "1px solid #ccc"}}>
                    <h3 style={{marginTop: 0}}>Заказы</h3>
                    <div style={{height: 300}}>
                        <OneSellerRoundGraphic />
                    </div>
            </div>
        </div>
    )
}
import { createSlice } from '@reduxjs/toolkit'
import { gatewayURL } from '../api'
import axios from 'axios'
axios.defaults.withCredentials = true;

export const monitorData = createSlice({
    name: 'monitorData',
    initialState: {
        monitorTime: new Date().getTime(),
        monitorTimeTwo: new Date().getTime(),
        sales_data: null,
        orders_data: null,
        sales_radarData: null,
        orders_radarData: null,
        sales_monthData: null,
        orders_monthData: null,
        sales_stringData: null,
        orders_stringData: null,
        sales_leaderData: null,
        orders_leaderData: null,
        dumpingLeaders: null,
    },
    reducers: {
        setMonitorTime: (state, action) => {
            state.monitorTime = action.payload
        },
        setMonitorTimeTwo: (state, action) => {
            state.monitorTimeTwo = action.payload
        },
        setSalesData: (state, action) => {
            state.sales_data = action.payload
        },
        setOrdersData: (state, action) => {
            state.orders_data = action.payload
        },
        setMonthData: (state, action) => {
            state.sales_monthData = action.payload
        },
        setOrdersMonthData: (state, action) => {
            state.orders_monthData = action.payload
        },
        setStringData: (state, action) => {
            state.sales_stringData = action.payload
        },
        setOrdersStringData: (state, action) => {
            state.orders_stringData = action.payload
        },
        setLeaderData: (state, action) => {
            state.sales_leaderData = action.payload
        },
        setOrdersLeaderData: (state, action) => {
            state.orders_leaderData = action.payload
        },
        setRadarData: (state, action) => {
            state.sales_radarData = action.payload
        },
        setOrdersRadarData: (state, action) => {
            state.orders_radarData = action.payload
        },
        setDumpingLeaders: (state, action) => {
            state.dumpingLeaders = action.payload
        },
    }
})

export const { setOrdersRadarData, setMonitorTimeTwo, setOrdersLeaderData, setSalesData, setOrdersData, setMonthData, setOrdersMonthData, setStringData, setOrdersStringData, setLeaderData, setRadarData, setMonitorTime, setDumpingLeaders } = monitorData.actions

export const getBarDataMonitor = (monitor) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/${monitor}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                if (monitor === 'sales') {
                    dispatch(setSalesData(response.data.result))
                } else {
                    dispatch(setOrdersData(response.data.result))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            if (monitor === 'sales') {
                dispatch(setSalesData(null))
            } else {
                dispatch(setOrdersData(null))
            }
        });
}

export const getMonthDataMonitor = (monitor) => (dispatch, getState) => {
    let token = getState().auth.token

    let date = new Date()
    let dt = date.toISOString().split('T')[0]
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/${monitor}/month?date=${dt}`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                if (monitor === 'sales') {
                    dispatch(setMonthData(response.data.result))
                } else {
                    dispatch(setOrdersMonthData(response.data.result))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            if (monitor === 'sales') {
                dispatch(setMonthData(null))
            } else {
                dispatch(setOrdersMonthData(null))
            }
        });
}

export const getStringDataMonitor = (monitor) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/${monitor}/string`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                if (monitor === 'sales') {
                    dispatch(setStringData(response.data.result))
                } else {
                    dispatch(setOrdersStringData(response.data.result))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            if (monitor === 'sales') {
                dispatch(setStringData(null))
            } else {
                dispatch(setOrdersStringData(null))
            }
        });
}

export const getLeaderDataMonitor = (monitor) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/${monitor}/leader`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                if (monitor === 'sales') {
                    dispatch(setLeaderData(response.data.result))
                } else {
                    dispatch(setOrdersLeaderData(response.data.result))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            if (monitor === 'sales') {
                dispatch(setLeaderData(null))
            } else {
                dispatch(setOrdersLeaderData(null))
            }
        });
}

export const getRadarDataMonitor = (monitor) => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/monitor/${monitor}/radar`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result) {
                if (monitor === 'sales') {
                    dispatch(setRadarData(response.data.result))
                } else {
                    dispatch(setOrdersRadarData(response.data.result))
                }
            }
        })
        .catch((error) => {
            console.log(error);
            if (monitor === 'sales') {
                dispatch(setRadarData(null))
            } else {
                dispatch(setOrdersRadarData(null))
            }
        });
}

export const getDumpingLeaders = () => (dispatch, getState) => {
    let token = getState().auth.token
    
    let config = {
        method: 'get',
        url: `${gatewayURL}/api/v1/b/dumping/raiting`,
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };

    axios.request(config)
        .then((response) => {
            if (response.data.result && response.data.result.data) {
                dispatch(setDumpingLeaders(response.data.result))
            }
        })
        .catch((error) => {
            console.log(error);
            dispatch(setDumpingLeaders(null))
        });
}

export default monitorData.reducer
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { gatewayURL } from "../../../../store/api"

const MLeader = ({monitor}) => {

    const leaderData = useSelector((state) => state.monitorData[`${monitor}_leaderData`])

    const [list, setList] = useState([])

    useEffect(() => {
        if (leaderData) {
            let tmp = []

            leaderData.data.forEach(element => {
                let e = Object.assign({}, element)
                if (!e.count) {
                    e.count = 0
                }
                if (!e.index) {
                    e.index = 0
                }

                tmp.push(e)
            });

            tmp = tmp.sort((a, b) => b.count - a.count)
    
            setList(tmp)
        }
    }, [leaderData])

    if (list.length === 0) {
        return null
    }

    return (
        <div className="compare-monitor">
            <h3>Лидеры {monitor === "sales" ? "продаж" : "заказов"} за 14 дней</h3>
            <div className="flex">
                {list.map((e, i) => <Item key={i} e={e} index={i}/>)}
            </div>
        </div>
    )
}

export default MLeader

const Item = ({e, index}) => {

    if (index > 7) return null
    //const i = 'https://elwin.ru/upload/iblock/76c/76c97c8525b5ef65bfbf662b7113fb37.png'

    return (
        <div  className="m-leader">
            <div className="m-leader-count">{e.count} шт.</div>
            <div className="m-leader-title">{e.product.title}</div>
            <div className="m-leader-image">
                <img style={{maxHeight: 100}} src={`${gatewayURL}/api/v1/b/images/${e.product.ID}/${e.product.preview}`} alt={e.product.title} />
               {/* <img src={`${i}`} alt={e.product.title} /> */}
            </div>
            <div className="m-index-block">
                <div className="m-index-block-n">{index + 1}</div>
                <div className="m-index-block-i"><Index s={e.index}/></div>
            </div>
        </div>
    )
}

const Index = ({s}) => {
    if (s > 0) {
        return (
            <div className="index-block up">
                <span>+{s}</span>
                <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9254 7.54841L7.52716 0.284668L0.128906 7.54841H4.76915L4.76915 18.0375H10.2891L10.2891 7.54841H14.9254Z" fill="black"/>
                </svg>
            </div>

        )
    } else if (s < 0) {
        return (
            <div className="index-block down">
                <span>{s}</span>
                <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.9254 11.4516L7.52716 18.7153L0.128906 11.4516H4.76915L4.76915 0.962543H10.2891L10.2891 11.4516H14.9254Z" fill="white"/>
                </svg>
            </div>

        )
    } else {
        return null
    }    
}